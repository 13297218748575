import { vatNoticeContent } from './vat-notice-content'

const supportContact = '<a href="tel:+4570227021">70 22 70 21</a>'
const supportOpenHours = '(man-fre 9:00-15:00)'

const translations = {
  close: 'Luk',
  webshop: 'Webshop',
  loading: 'Indlæser...',
  sku: 'Varenr.:',
  moreInfo: 'Mere info',
  backToList: 'Tilbage',
  goBack: 'Gå tilbage',
  showAsGrid: 'Vis som gitter',
  showMoreFilters: 'Vis flere',
  showLessFilters: 'Vis færre',
  error: 'Fejl',
  communicationBreakdown:
    'Der opstod et problem under kommunikationen med serveren.',
  sort: 'Sortering',
  showProductsButtonLabel: 'Vis produkter (%1)',
  orderProduct: 'Bliv kontaktet',
  suggestions: 'Forslag',
  exVatLabel: 'Ekskl. moms',
  noResults:
    'Der kunne desværre ikke findes nogle produkter, der matcher dine filtre.',
  contactFormName: 'Kontaktperson',
  contactFormCompany: 'Firma',
  contactFormPhone: 'Telefon',
  contactFormEmail: 'E-mail',
  contactFormComments: 'Besked',
  contactFormNameError: 'Du skal indtaste et navn.',
  contactFormCompanyError: 'Du skal indtaste et firmanavn.',
  contactFormPhoneError: 'Du skal indtaste et telefonnummer.',
  contactFormEmailError: 'Du skal indtaste en gyldig e-mail adresse.',
  contactFormDefaultComment: 'Jeg er interesseret i at høre mere om %1.',
  contactFormCancel: 'Fortryd',
  contactFormSubmit: 'Send',
  contactFormErrorsFoundMessage: 'Der er fejl i de indtastede data.',

  loginPopupTitle: 'Har du et login til TDC Erhvervs webshop?',
  loginPopupText: `<p>Hvis du er eksisterende TDC Erhverv kunde og har et login til vores webshop, så kan du logge ind her.</p>
        <p>Er du i tvivl om du har et login, så ring på ${supportContact}<br/>
        ${supportOpenHours}</p>`,
  loginPopupLinkTitle: 'Log på',
  loginPopupDismissTitle: 'Fortsæt uden at logge på',

  orderProductDialogTitle: 'Bliv kontaktet af TDC Erhverv',
  orderProductDialogTitleThanks: 'Tak for din henvendelse',
  orderProductDialogText: `<p>Ring til os på ${supportContact} ${supportOpenHours}</p><p>Du kan også udfylde formularen herunder, så kontakter vi dig i løbet af 1 arbejdsdag</p>`,
  orderProductDialogMailError:
    'Der opstod en fejl under afsendelsen. Hvis fejlen bliver ved med at opstå vil vi anbefale, at du ringer til os i stedet.',
  orderProductDialogMailSentText:
    '<p>Din henvendelse er modtaget. Du vil høre fra os indenfor en arbejdsdag.</p>',

  product: {
    specifications: 'Specifikationer',
    description: 'Beskrivelse',
  },

  productFilters: {
    header: 'Aktive filtre',
    filters: 'Kategorier',
    removeAllFilters: 'Fjern alle filtre',
    categoryChipLabel: 'Kategori: {{categoryName}}',
    searchChipLabel: 'Søgning: {{searchQuery}}',
  },

  productCategories: {
    header: 'Kategorier',
    categories: 'Kategorier',
    allCategories: 'Alle kategorier',
  },

  search: {
    inputPlaceholder: 'Søg efter produkter',
    loading: 'Henter forslag...',
    noResults:
      'Der var desværre ingen resultater af din søgning. Prøv eventuelt et simplere søgeord?',
  },

  basket: {
    outOfStock: 'Ikke på lager',
    basketPageTitle: 'Din bestilling',
    basketPageContinueBrowsing: 'Forsæt med at handle',
    basket: 'Kurv',
    miniBasketButton: 'Din kurv',
    closeMiniBasket: 'Luk kurv',
    miniBasketHeader: 'Din kurv',
    goToBasketPage: 'Gå til din bestilling',
    addToBasket: 'Læg i kurven',
    remove: 'Fjern',
    removeFromBasket: 'Fjern fra kurven',
    proceedToCheckout: 'Fortsæt til bestilling',
    basketTotal: 'Total: {{basketTotal}}',
    basketLineHeaderProduct: 'Produkt',
    basketLineHeaderDelivery: 'Leveringsdato',
    basketLineHeaderAmount: 'Antal',
    basketLineHeaderPricePerUnit: 'Pris pr. styk',
    basketLineHeaderPriceTotal: 'Samlet pris',
    basketLineAmount: '{{amount}} stk.',
    basketLineProductId: 'Varenr: {{productId}}',
    itemsInBasket: 'Varer i kurv',
    itemsInBasketAmount: '{{amount}} varer i kurven',
    priceTable: {
      totalExclVat: 'Sub total',
      discountAmount: 'Rabat (terminaltilskud)',
      freight: 'Fragt ekskl. moms',
      vat: 'Moms',
      totalInclVat: 'Pris i alt inkl. moms',
    },
    errors: {
      general: 'Beklager, der er opstået en fejl. Prøv igen senere',
      insufficientStock:
        'Beklager, {{amountRemoved}} stk af {{productReference}} er ikke på lager længere',
      productUnavailable:
        'Beklager, {{productReference}} er ikke på lager længere',
      orderAboveLimit: `<strong>For stor ordre</strong><br />
            For at sikre mod svindel, er det ikke muligt at lægge ordrer for mere end 25.000 dkr.<br />
            <br/>
            Ring til os på ${supportContact} ${supportOpenHours}, så hjælper vi dig med at få lagt ordren.`,
    },
    vatNoticeHeadline: 'Faktureres uden moms jf. omvendt betalingspligt',
    vatNoticeContent,
  },
  breadcrumbsLabel: 'Breadcrumbs',
  removeOne: 'Fjern en',
  addOne: 'Tilføj en',
  checkout: {
    invoice: {
      header: 'Faktureringsinformation',
      cvrNumber: 'Virksomhed / Organisation',
      cvrNumberHelpText: 'Fremsøg information via CVR register',
      companyName: 'Firmanavn',
      address: 'Adresse',
      attention: 'Att. navn',
      email: 'E-mail',
      mobilePhone: 'Mobilnummer (valgfri)',
      mobilePhoneHelpText:
        'Mobilnummeret vil kun blive brugt til at notificere ift. levering',
      rabatkode: 'Rabatkode (valgfri)',
      rabatkodeHelpText: 'Hvis du har en rabatkode, tilføjes den her',
      rabatkodeErrorText: 'Den indtastede rabatkode er ikke korrekt. Prøv igen',
    },
    delivery: {
      header: 'Leveringsinformation',
      deliverToOtherAddress: 'Ønskes leveret til en anden adresse',
      deliverToOtherAddressForbidden: `For at sikre jer mod svindel, er det ikke muligt at ændre leveringsadressen online.<br/>Ring til os på ${supportContact} ${supportOpenHours}, så hjælper vi dig med at få lagt ordren`,
      deliveryFootnote:
        'Vi leverer med standard Post Nord Erhvervs pakkelevering. Pris 0 kr.',
      companyName: 'Firmanavn',
      address: 'Adresse',
      coName: 'Att. navn',
      email: 'E-mail',
      zip: 'Postnr.',
      city: 'By',
    },
    paymentMethods: 'Vi tager følgende betaling:',
    continueToPayment: 'Fortsæt til betaling',
    backToBasket: 'Tilbage til kurv',
    fieldMustBeFilled: 'Dette felt skal udfyldes korrekt',
    emailMustBeValid: 'Du skal indtaste en gyldig e-mail adresse',
    nameMustBeValid: 'Beklager! Feltet kan kun indeholde bogstaver',
    deliveryInformation: {
      header: 'Information om levering',
      content: '<p>Leveringsinformationer her</p>',
    },
    returnInformation: {
      header: 'Ombytning eller tilbagelevering',
      content: '<p>Returinformationer her</p>',
    },
    terms: {
      linkPrefix: 'Jeg accepterer',
      linkTitle: 'betingelserne',
      linkAriaTitle: 'Vis betingelserne',
      error: 'Du skal acceptere betingelserne.',
      dialogTitle: 'Salgs- og leveringsbetingelser',
    },
    basket: { header: 'Din kurv', edit: 'Redigér kurv' },
    errorDuringCheckout: 'Fejl under behandling af data',

    deliveredToCompanyAddress: 'Leveres til virksomhedens hovedadresse:',

    cvr: {
      invalidHeader: 'Beklager! Der er et problem med dit CVR nummer',
      invalidText: `Ring til os på ${supportContact} ${supportOpenHours}, så hjælper vi dig med at få lagt ordren.`,
      noOptions: 'Ingen resultater fundet.',
      loading: 'Indlæser...',
    },

    addVoucher: 'Tilføj',
  },

  getHelp: {
    header: 'Få hjælp til at vælge det rigtige produkt',
    content:
      '<p>TDC Erhverv er klar til at hjælpe med både råd og vejledning.</p>',
  },

  orderConfirmation: {
    headerTitle: 'Tak for din ordre!',
    headerText:
      'Din kvittering er sendt til: <strong>{{email}}</strong>.<br/>Du vil modtage en e-mail inden for få minutter.<br/>Ref. ordrenummer <strong>{{orderNumber}}</strong>',
    invoiceInfo: 'Faktureringsinformation',
    shippingInfo: 'Leveringsadresse',
    mobile: 'Mobil:',
    email: 'E-mail:',
    paymentInfo: 'Betaling',
    card: 'Kort',
    yourOrder: 'Din bestilling',
    orderErrorHeader: 'Der er opstået en ukendt fejl',
    orderErrorText: `Der er sket en uventet fejl i forsøget på at hente dine ordredata ud af systemet.<br/><br/>Ring til os på ${supportContact} ${supportOpenHours}, så ser vi, hvad vi kan gøre.`,
    attentionTo: 'Att. {{attentionTo}}',
  },

  cardTypes: {
    MC: 'MasterCard',
    VISA: 'VISA',
    'V-DK': 'VISA-Dankort',
    DK: 'Dankort',
    MTRO: 'Maestro',
    ELEC: 'VISA Electron',
  },
}

type ITranslationObject = { [key: string]: ITranslationObject | string }

export const translate = (
  labelName: keyof typeof translations | string,
  interpolation?: Record<string, string>,
): string => {
  let translation = (labelName
    .split('.')
    .reduce(
      (accumulator: ITranslationObject | string, currentValue: string) =>
        accumulator[currentValue],
      translations,
    ) || labelName) as string
  if (interpolation) {
    Object.entries(interpolation).forEach(([key, value]) => {
      translation = translation.replace(`{{${key}}}`, value)
    })
  }
  return translation
}
