import React, { ReactElement } from 'react'
import { graphql, Link as gLink, useStaticQuery } from 'gatsby'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import NuudayLogo from '@tdcerhverv/parrotfish/dist/icons/Icons/nuuday_simple_logo.svg'

const useStyles = makeStyles(theme => ({
  footerLogo: {
    width: 160,
    height: 16,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  footerBottom: {
    '& > *': {
      paddingRight: 24,
    },
  },
  bottomFooterLink: {
    ...theme.typography.caption,
    color: theme.palette.common.black,
  },
  bottomSection: {
    marginTop: 38,
  },
  [theme.breakpoints.down('sm')]: {
    bottomSection: {
      marginTop: 32,
    },
    copyRight: {
      display: 'block',
      marginBottom: 12,
    },
    footerLogoContainer: {
      marginTop: 32,
      justifyContent: 'flex-start',
    },
  },
}))

const BottomFooterSection = (): ReactElement => {
  const data = useStaticQuery(graphql`
    {
      footerBottomLinks: contentfulModuleComponentBundle(
        contentful_id: { eq: "4xoF712EIk9X6JCgCHC1hL" }
      ) {
        name
        resources {
          ...linkFragment
        }
      }
    }
  `)
  const classes = useStyles()

  return (
    <Grid
      spacing={4}
      className={classes.bottomSection}
      container
      justifyContent="center"
    >
      <Grid item xs={12} md={6} lg={6}>
        <Box className={classes.footerBottom}>
          <Typography
            className={classes.copyRight}
            component="span"
            variant="caption"
          >
            &copy; {new Date().getFullYear()}, TDC Erhverv
          </Typography>
          {data?.footerBottomLinks?.resources.map(linkData => {
            return (
              <Link
                key={linkData.contentful_id}
                component={gLink}
                className={classes.bottomFooterLink}
                to={'https://tdc.dk' + linkData.href}
              >
                {linkData.title}
              </Link>
            )
          })}
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box
          className={classes.footerLogoContainer}
          display="flex"
          justifyContent="flex-end"
        >
          <Link href="https://nuuday.dk/">
            <SvgIcon
              className={classes.footerLogo}
              component={NuudayLogo}
              viewBox="0 0 200 20"
            />
            <Typography variant="srOnly">A Nuuday Company</Typography>
          </Link>
        </Box>
      </Grid>
    </Grid>
  )
}

export default BottomFooterSection
