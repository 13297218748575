import React, { ReactElement } from 'react'
import { IFooterMenu, IMenuLink } from './types'
import LinkConverter from '../LinkConverter'
import LogoColumn from './components/LogoColumn'
import InformationColumn from './components/InformationColumn'
import NewsletterColumn from './components/NewsletterColumn'
import BottomFooterSection from './components/BottomFooterSection'

// MUI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  titles: {
    marginBottom: 16,
  },
  linkList: {
    '& .MuiListItem-root': {
      padding: `0 0 14px 0`,
    },
    '& .MuiLink-root': {
      color: theme.palette.common.black,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const Footer = (): ReactElement => {
  const classes = useStyles()

  const {
    footer: { menu },
  } = useStaticQuery(graphql`
    query FooterQuery {
      footer: contentfulModuleComponentBundle(
        contentful_id: { eq: "1GAm5JlqhSY2C4Yt7UV5Gr" }
      ) {
        ...footerFragment
      }
    }
  `)
  return (
    <Box component="footer" className={classes.root} pt={8} pb={6}>
      <Container>
        <LogoColumn />
        <Grid spacing={4} justifyContent="center" container>
          <InformationColumn />
          <Hidden lgUp>
            <NewsletterColumn />
          </Hidden>
          {menu.map((menuItem: IFooterMenu, index: number) => {
            return (
              <Grid
                item
                key={index}
                xs={12}
                md={6}
                lg={menu.length === index + 1 ? 3 : 2}
              >
                <Typography
                  component="h2"
                  className={classes.titles}
                  variant="subtitle1"
                >
                  {menuItem.title}
                </Typography>
                <List className={classes.linkList} disablePadding>
                  {menuItem.links.map(
                    (
                      { href, title, linkText, tooltip }: IMenuLink,
                      index: number,
                    ) => {
                      return (
                        <ListItem key={index}>
                          <LinkConverter
                            linkHref={href}
                            linkTitle={title}
                            isTooltip={tooltip}
                          >
                            {linkText}
                          </LinkConverter>
                        </ListItem>
                      )
                    },
                  )}
                </List>
              </Grid>
            )
          })}
          <Hidden mdDown>
            <NewsletterColumn />
          </Hidden>
        </Grid>
        <BottomFooterSection />
      </Container>
    </Box>
  )
}

export default Footer
