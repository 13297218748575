import React, { useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { CLUDO_ENGINE_ID } from '../../../../../constants'

//MUI Components
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ISearchMenu } from './SearchMenu.type'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'
import { Theme } from '@material-ui/core'
import Search from '../Search'
import { searchDataDistributor } from '../../../../utils/searchDataDistributor'

const useStyles = makeStyles<Theme>(theme => ({
  searchMenuControls: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > img': {
      display: 'none',
    },
  },
  title: {
    ...theme.typography.subtitle1,
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 500,
    background: 'none',
    border: 0,
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
      cursor: 'pointer',
    },
  },
  titleIcon: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
}))

const SearchMenu = ({ open, handleClose }: ISearchMenu) => {
  const ref = useRef(null)

  useEffect(() => {
    const searchField = ref.current.querySelector('#searchTextField')
    if (open) {
      searchField.focus()
    } else {
      searchField.blur()
    }
  }, [open])

  const data = useStaticQuery(graphql`
    {
      images: contentfulModuleComponentBundle(
        contentful_id: { eq: "iGoosu86m6yxjIsnu106N" }
      ) {
        ...searchDataFragment
      }
    }
  `)

  const classes = useStyles()
  return (
    <div ref={ref}>
      <Box
        className={classes.searchMenuControls}
        mb={5}
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          onClick={() => handleClose && handleClose(false)}
          component="button"
          className={classes.title}
        >
          <SvgIcon
            className={classes.titleIcon}
            component={iconsMap['Expand_left']}
            viewBox="0 0 48 48"
          />
          <SvgIcon
            className={classes.titleIcon}
            component={iconsMap['Search']}
            viewBox="0 0 48 48"
          />
          <span>Søg</span>
        </Typography>
      </Box>
      <Search
        className={classes.search}
        searchData={searchDataDistributor(data)}
        headline="Find hvad du søger på tdc.dk"
        engineId={CLUDO_ENGINE_ID}
        basePath="https://tdc.dk"
      />
    </div>
  )
}

export default SearchMenu
