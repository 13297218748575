import React, { useState, useRef, useEffect } from 'react'

//Assets
import TdcLogoMain from '../../../static/tdc_logo_primary.svg'
import TdcLogoMainInverted from '../../../static/tdc_logo_primary_negative_light.svg'
import { iconsMap } from '@tdcerhverv/parrotfish/dist/icons/icons'

//Components
import { usePageProvider } from '../../context/PageProvider'
import { INavigation, INavItems, INavStyles } from './Navigation.types'
import NavigationItems from './components/NavigationItems'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import MegaMenu from './components/MegaMenu'
import SearchMenu from './components/SearchMenu'
import Dropdown from './components/Dropdown'
import MobileMenu from './components/MobileMenu'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'

// MUI Components
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Hidden from '@material-ui/core/Hidden'
import Backdrop from '@material-ui/core/Backdrop'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { parse } from 'query-string'

const useStyles = makeStyles(({ palette, breakpoints, zIndex }) => ({
  root: {
    width: '100%',
    backgroundColor: ({ animationCondition }: INavStyles) =>
      animationCondition ? 'rgba(255,255,255, 1)' : 'rgba(255,255,255, 0)',
    filter: ({ animationCondition, isMobile }: INavStyles) =>
      animationCondition || isMobile
        ? 'drop-shadow(0px 10px 15px rgba(25, 34, 40, 0.05)) drop-shadow(0px 2px 6px rgba(25, 34, 40, 0.05))'
        : null,
    marginBottom: -84,
    position: ({ isScrolling, isMobile }: INavStyles) =>
      isScrolling && !isMobile ? 'fixed' : 'sticky',
    top: ({ isMobile }: INavStyles) => (isMobile ? 0 : -200),
    zIndex: zIndex.appBar,
    transition: 'all 300ms ease',

    '& a': {
      color: ({ darkFontTopNav, animationCondition }: INavStyles) =>
        darkFontTopNav || animationCondition
          ? palette.common.black
          : palette.common.white,
    },
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 1)',

      '& a': {
        color: palette.common.black,
      },
    },
    [breakpoints.down('sm')]: {
      height: '100%',
      backgroundColor: 'rgba(255,255,255, 1)',
      '& a': {
        color: palette.common.black,
      },
    },
  },
  scrolled: {
    width: '100vw',
    top: ({ isMobile }: INavStyles) => (isMobile ? 0 : 0),
    '& $navWrapper': {
      paddingTop: 12,
      paddingBottom: 12,
    },

    '& $logo': {
      width: 90,
      height: 40,
    },
  },
  navWrapper: {
    transition: 'all 300ms ease',
    paddingTop: 20,
    paddingBottom: 20,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 99,
    height: 44,
  },
  hamburgerMenuControl: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

const Navigation = ({ pageVariant }: INavigation) => {
  //GET DATA FOR NAVIGATION
  const { navigation, navigationSMB } = useStaticQuery(graphql`
    query NavigationQuery {
      navigation: contentfulModuleComponentBundle(
        contentful_id: { eq: "2auBFRFxFBpPvllJXZG8Mc" }
      ) {
        ...navigationFragment
      }
      navigationSMB: contentfulModuleComponentBundle(
        contentful_id: { eq: "6GzSlTyhYuIa5lJgpQbJV4" }
      ) {
        ...navigationFragment
      }
    }
  `)

  const { navItems }: { navItems: INavItems[] } =
    pageVariant === 'SMB' ? navigationSMB : navigation
  const ref = useRef(null)
  const { darkFontTopNav } = usePageProvider()
  const [navBarHeight, setNavBarHeight] = useState<number>(0)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [activeNav, setActiveNav] = useState<number | null>(null)
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const isScrolling = useScrollTrigger({
    disableHysteresis: true,
    threshold: 90,
  })
  const hasScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 150,
  })

  const megaMenuIsOpen = activeNav && activeNav >= 0
  const animationCondition =
    hasScrolled ||
    isHovered ||
    isMobile ||
    megaMenuIsOpen ||
    searchOpen ||
    mobileMenuOpen

  const menuDropDownCondition = megaMenuIsOpen || searchOpen || mobileMenuOpen

  const handleActiveNav = (navItem: number) => {
    if (megaMenuIsOpen && activeNav === navItem + 1) {
      setActiveNav(null)
    } else {
      setActiveNav(navItem + 1)
    }
  }

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen)
    setActiveNav(null)
  }

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(!mobileMenuOpen)
    setActiveNav(null)
    setSearchOpen(false)
  }

  const handleBackdropClick = () => {
    setActiveNav(null)
    setSearchOpen(false)
    setMobileMenuOpen(false)
  }

  const location = useLocation()
  const { cludoquery } = parse(location.search)

  useEffect(() => {
    setSearchOpen(false)
  }, [cludoquery])

  useEffect(() => {
    // Setting the exact height of the Navigationbar. To be used when dropdown should take up the remaining screen height.
    const alertBanners = document.querySelectorAll('[role="alert"]')
    const splitMenu =
      document.getElementById('split-menu')?.getBoundingClientRect()?.height ||
      0
    const navHeight = ref.current?.getBoundingClientRect()?.height
    if (alertBanners.length > 0) {
      alertBanners.forEach(el => {
        const bannerHeight = el.getBoundingClientRect().height
        setNavBarHeight(
          splitMenu + navHeight + Math.max(0, bannerHeight - window.scrollY), // Trust the Math
        )
      })
    } else {
      setNavBarHeight(splitMenu + navHeight)
    }

    if (!isMobile) {
      setMobileMenuOpen(false)
    } else {
      setActiveNav(null)
      setSearchOpen(false)
    }
  }, [isMobile, hasScrolled, mobileMenuOpen])

  const classes = useStyles({
    darkFontTopNav,
    animationCondition,
    isMobile,
    isScrolling,
  })
  return (
    <>
      <header
        ref={ref}
        id="main-navigation"
        className={`${classes.root} ${hasScrolled && classes.scrolled}`}
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
      >
        <Container className={classes.navWrapper}>
          <Grid
            spacing={3}
            container
            justifyContent="space-between"
            alignItems="center"
            component="nav"
          >
            <Grid
              className={classes.logoWrapper}
              item
              xs={6}
              sm={2}
              md={2}
              lg={3}
            >
              {/* Skip to main Content (a11y) */}
              <Link id="skip-to-main" href="#main" variant="srOnly">
                Spring til hovedindhold
              </Link>
              {/* TDC Logo */}
              <GatsbyLink
                style={{ display: 'inline-flex' }}
                title="Tilbage til forsiden"
                to="https://tdc.dk"
                data-id="cy-tdc-logo"
              >
                <SvgIcon
                  viewBox="0 0 350 157"
                  className={classes.logo}
                  component={
                    animationCondition || darkFontTopNav
                      ? TdcLogoMain
                      : TdcLogoMainInverted
                  }
                />
              </GatsbyLink>
            </Grid>
            <Hidden smDown>
              <NavigationItems
                {...(pageVariant !== 'DEFAULT' && { navItems: navItems })}
                handleActiveNav={handleActiveNav}
                activeNav={activeNav}
                handleSearchOpen={handleSearchOpen}
                searchOpen={searchOpen}
                animationCondition={animationCondition}
                darkFontTopNav={darkFontTopNav}
              />
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={6} className={classes.hamburgerMenuControl}>
                {/* Hamburger button */}
                <IconButton onClick={handleMobileMenuOpen}>
                  <SvgIcon
                    component={
                      !mobileMenuOpen
                        ? iconsMap['Menu_hamburger']
                        : iconsMap['Close']
                    }
                    viewBox="0 0 48 48"
                    aria-label="Åben eller luk menuen"
                  />
                  <Typography variant="srOnly">Åben mobilmenuen</Typography>
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
        <Dropdown navBarHeight={navBarHeight} open={menuDropDownCondition}>
          {(!searchOpen || !mobileMenuOpen) && (
            <>
              {navItems.map((navItem, index) => (
                <MegaMenu
                  key={index}
                  refObject={ref}
                  handleClose={setActiveNav}
                  navItem={navItem}
                  active={activeNav - 1 === index}
                />
              ))}
            </>
          )}
          {searchOpen && <SearchMenu refObject={ref} open={searchOpen} />}
          {mobileMenuOpen && <MobileMenu navItems={navItems} />}
        </Dropdown>
      </header>
      <Backdrop
        onClick={handleBackdropClick}
        style={{ zIndex: 1000 }}
        open={menuDropDownCondition}
      />
    </>
  )
}

export default Navigation
